import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import { css } from '@emotion/core'
import Img from 'gatsby-image'
import { getLocalStorage, setLocalStorage } from '../utils'

import SEO from '../components/seo'
import CheckBox from '../components/CheckBox'
import { graphql } from 'gatsby'
import { parseRepositoryPage } from '../parsers'
import * as commonStyles from '../commonStyles'
import ContentGrid from '../components/ContentGrid'
import GridIcon from '../images/grid.inline.svg'
import ListIcon from '../images/list.inline.svg'

const flatDeep = (arr, depth = 1) => {
	return depth > 0 ? arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? flatDeep(val, depth/ - 1) : val), [])
				 : arr.slice();
 };

const ascSort = ((itemA, itemB) => {
	if (itemA < itemB) {
		return -1
	}
	if (itemA > itemB) {
		return 1
	}
	return 0
})

const tileStyle = (viewStyle) => (theme) => css`
	position: relative;
	display: ${viewStyle === 'grid' ? 'block' : 'flex'};
	margin-bottom: ${viewStyle === 'grid' ? '0' : '10px'};

	.gatsby-image-wrapper {
		background-color: ${theme.colors.blue};
		height: 104px;
		width: ${viewStyle === 'grid' ? 'auto' : '104px'};
		margin-right: ${viewStyle === 'grid' ? '0' : '10px'};
	}

	&:hover {
		.title {
			display: block;
		}
	}
`

const clampStyle = css`
	display: -webkit-box;
	-webkit-line-clamp: 5;
	-webkit-box-orient: vertical;
	overflow: hidden;
	hyphens: auto;
`

const tileTitleStyle = (viewStyle) => (theme) => css`
	position: ${viewStyle === 'grid' ? 'absolute' : 'relative'};
	bottom: 0;
	z-index: 1;
	left: 0;
	right: 0;
	top: 0;
	padding: ${viewStyle === 'grid' ? '5px' : '0px'};
	display: ${viewStyle === 'grid' ? 'none' : 'block'};

	background-color: ${theme.colors.white};
	color: ${theme.colors.blue};
`

const tileContainer = (viewStyle) => (theme) => css`
	display: grid;
	grid-template-columns: ${viewStyle === 'grid' ? '1fr 1fr 1fr 1fr 1fr 1fr' : '1fr'};
	column-gap: 12px;
	row-gap: ${viewStyle === 'grid' ? '12px' : '4px'};

	@media (max-width: 1000px) {
		grid-template-columns: ${viewStyle === 'grid' ? '1fr 1fr 1fr 1fr 1fr' : '1fr'};
	}

	${theme.breakpoints.tablet} {
		grid-template-columns: ${viewStyle === 'grid' ? '1fr 1fr 1fr 1fr' : '1fr'};
	}

	${theme.breakpoints.mobile} {
		grid-template-columns: ${viewStyle === 'grid' ? '1fr 1fr 1fr' : '1fr'};
	}
`

const inputStyle = (theme) => css`
	${theme.typography.big};
	color: inherit;
	border: 1px solid ${theme.colors.blue};
	width: 100%;
	padding: 5px;
	margin-top: 5px;
`

const linkStyle = (theme) => css`
	${theme.typography.basic};
	text-decoration: none;
`

const checkboxesContainer = (theme) => css`
	display: flex;
	flex-direction: column;

	${theme.breakpoints.mobile} {
		display: grid;
		grid-template-columns: 90px 90px 90px;
	}
`

const label = css`
	margin: 0 0 20px 0;
`

const searchTools = css`
	margin-top: 10px;
	grid-area: filters;
`

const linkButtonStyle = (active) => (theme) => css`
	${commonStyles.resetButton};
	${theme.typography.basic};
	padding: 0;
	margin-left: 3px;
	text-decoration: ${active ? 'underline' : 'none'};
`

const additionalParamsContainer = css`
	margin: 10px 0;
`

const searchToolsHeader = css`
	display: flex;
	flex-direction: column;
	grid-area: sidebar;
`

const buttonStyle = (theme) => css`
	${commonStyles.resetButton};
	${theme.typography.basic};
	padding: 0;
	margin-right: 50px;
	margin-bottom: 10px;
	text-align: left;

	svg {
		display: block;
	}

	rect {
		fill: ${theme.colors.blue};
	}

	line {
		stroke: ${theme.colors.blue};
	}
`

const labelStyle = css`
	margin: 5px 0;
	display: block;
`

const repositoryGrid = (theme) => css`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-template-rows: auto auto 1fr;
	grid-template-areas:
		'. search search search search search search'
		'sidebar filters filters filters filters filters filters'
		'sidebar content content content content content content';

	${theme.breakpoints.mobile} {
		grid-template-columns: 1fr;
		grid-template-areas:
			'search'
			'sidebar'
			'filters'
			'content';
	}
`

const gridSearch = css`
	grid-area: search;
`

const repositoryGridContent = css`
	grid-area: content;
`

const viewStyleControls = (theme) => css`
	margin-top: 60px;
	${theme.breakpoints.mobile} {
		display: none;
	}
`

const filterValues = css`
	margin-bottom: 10px;
`

const phrases = {
	pl: {
		no_results: 'Niestety, nie znaleźliśmy dokumentów pasujących do wybranych kryteriów.',
		filters: 'Filtry:',
		crators: 'Twórcy',
		year: 'Rok',
		material: 'Materiał',
		search: 'Szukaj',
		browse: 'Przeglądaj:',
		grid: 'Kafle',
		list: 'Lista',
	},
	en: {
		no_results: 'Unfortunately, we did not find any documents matching the chosen criteria.',
		filters: 'Filters:',
		crators: 'Creators',
		year: 'Year',
		material: 'Material',
		search: 'Search',
		browse: 'Browse:',
		grid: 'Grid',
		list: 'List',
	},
}

const browserInitialView = () => {
	const isMobile = window.innerWidth <= 550
	if (isMobile) {
		return 'list'
	}
	return window.localStorage.getItem('listView') || 'grid'
}

const documentImageStyle = css`
	flex-shrink: 0;
`

const RepositoryPage = ({ data, pageContext }) => {
	const initialGrid = typeof window !== 'undefined' ? browserInitialView() : 'grid'
	const { documents } = parseRepositoryPage(data)
	const [searchValue, setSearchValue] = React.useState('')
	const [viewStyle, setViewStyle] = React.useState(initialGrid)
	const handleViewStyleChange = React.useCallback((view) => {
		setViewStyle(view)
		typeof window !== 'undefined' && window.localStorage.setItem('listView', view)
	}, [])
	const initialFilters = {
		year: null,
		creator: null,
		type: null,
	}
	const [filters, setFilters] = React.useState(initialFilters)
	const initialFiltersToggle = {
		year: false,
		creator: false,
		type: false,
	}
	const [filtersToggle, setFiltersToggle] = React.useState(initialFiltersToggle)
	const searchCreatorValues = React.useMemo(() => {
		return documents.map(document => ({
			...document,
			creatorsString: (document.creator && document.creator.map(_creator => _creator.toLowerCase()).join(' ')) || '',
		}))
	}, [documents])
	const filteredDocuments = React.useMemo(() => {
		const trimmed = searchValue.trim().toLowerCase()
		let filteringDocs = searchCreatorValues
		if (trimmed) {
			filteringDocs = filteringDocs.filter(
				(document) =>
					document.title.toLowerCase().indexOf(trimmed) !== -1 ||
					document.creatorsString.indexOf(trimmed) !== -1,
			)
		}
		if (filters.year) {
			filteringDocs = filteringDocs.filter((document) => document.year === filters.year)
		}
		if (filters.type) {
			filteringDocs = filteringDocs.filter((document) => document.type === filters.type)
		}
		if (filters.creator) {
			filteringDocs = filteringDocs.filter((document) => ((document.creator && document.creator.indexOf(filters.creator) !== -1) || (document.related_creator && document.related_creator.indexOf(filters.creator) !== -1)))
		}
		return filteringDocs
	}, [searchValue, filters, searchCreatorValues])
	const years = React.useMemo(() => {
		const mapped = documents.map((document) => document.year)
		return [...new Set(mapped)]
	}, [documents]).sort(ascSort)
	const types = React.useMemo(() => {
		const mapped = documents.map((document) => document.type)
		return [...new Set(mapped)]
	}, [documents])
	const creators = React.useMemo(() => {
		const mapped = documents.map((document) => document.creator)
		const mappedRelated = documents.map((document) => document.related_creator)
		return [...new Set(flatDeep([...mapped, ...mappedRelated]))].sort((itemA, itemB) => {
			const surnameA = itemA && itemA.split(' ')[1] || ''
			const surnameB = itemB && itemB.split(' ')[1] || ''
			return surnameA.localeCompare(surnameB)
		})
	}, [documents])

	const handleFilterClick = (filter) => () => {
		setFiltersToggle((prevFitlers) => ({ ...prevFitlers, [filter]: !filtersToggle[filter] }))
		setFilters(initialFilters)
	}

	React.useEffect(() => {
		const storedFilters = getLocalStorage('filters')
		if (storedFilters) {
			const parsed = JSON.parse(storedFilters)
			if (!parsed) {
				return
			}
			setFilters(parsed)
		}
	}, [])
	React.useEffect(() => {
		setLocalStorage('filters', JSON.stringify(filters))
	}, [filters])

	React.useEffect(() => {
		const storedFilters = getLocalStorage('filters_toggle')
		if (storedFilters) {
			const parsed = JSON.parse(storedFilters)
			if (!parsed) {
				return
			}
			setFiltersToggle(parsed)
		}
	}, [])
	React.useEffect(() => {
		setLocalStorage('filters_toggle', JSON.stringify(filtersToggle))
	}, [filtersToggle])

	const lang = pageContext.language
	let slug = '/repozytorium'
	let enSlug = '/en/repository'
	let linkToDifferentLanguage = enSlug
	if (lang === 'en') {
		linkToDifferentLanguage = slug
	}
	
	return (
		<Layout menu="2020" language={lang} linkToDifferentLanguage={linkToDifferentLanguage}>
			<SEO title="Repozytorium" />
			<ContentGrid>
				<div css={repositoryGrid}>
					<div css={gridSearch}>
						<label htmlFor="search">
							{phrases[lang].search}
							<input
								id="search"
								type="text"
								name="search"
								css={inputStyle}
								value={searchValue}
								onChange={(event) => setSearchValue(event.target.value)}
							/>
						</label>
					</div>
					<div css={searchToolsHeader}>
						<div>
							<div css={label}>{phrases[lang].filters}</div>
							<div css={checkboxesContainer}>
								<CheckBox
									label={phrases[lang].crators}
									onClick={handleFilterClick('creator')}
									checked={!!filters.creator || !!filtersToggle.creator}
								/>
								<CheckBox
									label={phrases[lang].year}
									onClick={handleFilterClick('year')}
									checked={!!filters.year || filtersToggle.year}
								/>
								<CheckBox
									label={phrases[lang].material}
									onClick={handleFilterClick('type')}
									checked={!!filters.type || filtersToggle.type}
								/>
							</div>
						</div>
						<div css={viewStyleControls}>
							<div css={label}>{phrases[lang].browse}</div>
							<div>
								<button css={buttonStyle} onClick={() => handleViewStyleChange('grid')}>
									<GridIcon />
									<span css={labelStyle}>{phrases[lang].grid}</span>
								</button>
								<button css={buttonStyle} onClick={() => handleViewStyleChange('list')}>
									<ListIcon />
									<span css={labelStyle}>{phrases[lang].list}</span>
								</button>
							</div>
						</div>
					</div>
					<div css={searchTools}>
						<div css={additionalParamsContainer}>
							{filtersToggle.creator && (
								<div css={filterValues}>
									{creators.map((creator) => (
										<button
											key={creator}
											css={linkButtonStyle(filters.creator === creator)}
											onClick={() =>
												setFilters((prevFilters) => ({
													...prevFilters,
													creator,
												}))
											}
										>
											{creator} |{' '}
										</button>
									))}
								</div>
							)}

							{filtersToggle.year && (
								<div css={filterValues}>
									{years.map((year) => (
										<button
											key={year}
											css={linkButtonStyle(filters.year === year)}
											onClick={() =>
												setFilters((prevFilters) => ({
													...prevFilters,
													year,
												}))
											}
										>
											{year} |{' '}
										</button>
									))}
								</div>
							)}

							{filtersToggle.type && (
								<div css={filterValues}>
									{types.map((type) => (
										<button
											key={type}
											css={linkButtonStyle(filters.type === type)}
											onClick={() =>
												setFilters((prevFilters) => ({
													...prevFilters,
													type,
												}))
											}
										>
											{type} |{' '}
										</button>
									))}
								</div>
							)}
						</div>
					</div>
					<div css={repositoryGridContent}>
						{filteredDocuments.length === 0 && <p>{phrases[lang].no_results}</p>}
						<div css={tileContainer(viewStyle)}>
							{filteredDocuments.map((document) => (
								<Link key={document.id} to={lang === 'en' ? document.enSlug : document.slug} css={linkStyle}>
									<div css={tileStyle(viewStyle)}>
										{document.images && (
											<Img
												fluid={{...document.images[0].image_source.childImageSharp.fluid, sizes:"(max-width: 3000px) 110px"}}
												css={documentImageStyle}
												alt=""
											/>
										)}
										<div css={tileTitleStyle(viewStyle)} className="title">
											<span css={clampStyle}>{document.title}</span>
										</div>
									</div>
								</Link>
							))}
						</div>
					</div>
				</div>
			</ContentGrid>
		</Layout>
	)
}

export const query = graphql`
	{
		allMarkdownRemark(
			filter: { frontmatter: { layout: { eq: "document" } } }
			sort: { fields: [frontmatter___title], order: ASC }
		) {
			edges {
				...DocumentDataEdge
			}
		}
	}
`

export default RepositoryPage
