import React from 'react'
import { css } from '@emotion/core'

const checkboxStyle = (checked) => (theme) => css`
	width: 25px;
	height: 25px;
	border: 1px solid ${theme.colors.blue};
	background-color: ${checked ? theme.colors.blue : theme.colors.white};
	cursor: pointer;
`

const labelStyle = css`
	margin: 5px 0 15px 0;
`

const CheckBox = ({ label, checked, onClick }) => {
	return (
		<div onClick={onClick}>
			<div tabIndex={0} css={checkboxStyle(checked)}></div>
			<div css={labelStyle}>{label}</div>
		</div>
	)
}

export default CheckBox
